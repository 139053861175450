
@media (width >= 600px) {
    
    .nav-cont{
        
        
    }
    .nav-title{
        
        
    }
    .nav-logo{
        width: 6rem;
        height: 6rem;
    }
    .face-icon{

        
    }

    .smile{
      
    }
    .success-icon{
        font-size: 5rem;
    }
   
}

@media (width <= 480px) {
    .nav-logo{
        width: 3rem;
        height: 3rem;
        margin-top: 0.5rem;
        margin-left: 1rem;
    }
    .nav-cont{
        display: flex;
        flex-direction: row;
    }
    
}